// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css');
body {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
}
.fw-medium {
    font-weight: 500;
}
.fw-bold {
    font-weight: 600 !important;
}
textarea {
    resize: none;
}
button:focus,
textarea:focus {
    box-shadow: none !important;
}
.automated-reminders {
    .reminder-box {
        position: relative;
        padding: 30px 15px;
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: calc(100% + 1.5rem);
            left: -0.75rem;
            background-color: #d8d8d8;
            bottom: 0;
        }
        .warning-box {
            background-color: #fcf7e4;
            border-color: #faf0ce !important;
            color: #876d40;
            small {
                font-size: 12px;
            }
        }
        .reminder-title {
            font-size: 20px;
            font-weight: 600;
        }
        .custom-noti-box {
            background-color: #f3f4f7;
            border-color: 9d9d9d;
            border-radius: 10px !important;
            .left-box {
                width: calc(100% - 75px);
            }
            .noti-btns {
                width: 32px;
                margin: 0 15px;
            }
            .noti-title {
                width: 200px;
                margin-right: 15px;
            }
            .noti-time,
            .noti-type {
                margin: 0 7px;
                min-width: 120px;
            }
            .noti-reminder {
                margin: 0 7px;
                min-width: 170px;
            }
            .noti-type {
                margin: 0 7px;
                min-width: 120px;
            }
            .noti-createdon {
                min-width: 140px;
            }
            .noti-edit {
                cursor: pointer;
            }
        }
    }
}
.noti-popups-sec {
    .form-select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.169' height='3.943' viewBox='0 0 8.169 3.943'%3E%3Cpath id='dropdown-icon' d='M3106.946,2498h8.169l-4.273,3.943Z' transform='translate(-3106.946 -2498)' fill='%23373a3c'/%3E%3C/svg%3E%0A");
        background-size: 8px auto;
    }
    .modal-dialog {
        max-width: 800px;
    }
    .btn-border {
        background-color: rgba(22, 118, 238, 0.1);
        border-color: #1676ee;
        color: #1676ee;
    }
    .btn-primary {
        background-color: #1676ee !important;
        border-color: #1676ee !important;
    }
    .modal-header {
        border-bottom: none;
        position: relative;
        padding: 1rem 1.5rem;
        &::after {
            content: "";
            position: absolute;
            background-color: #e9e9e9;
            width: calc(100% - 3rem);
            height: 1px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .btn-close {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.401' height='15.401' viewBox='0 0 15.401 15.401'%3E%3Cpath id='Path_4523' data-name='Path 4523' d='M5.445,0a.681.681,0,0,1,.681.681V4.764H10.21a.681.681,0,0,1,0,1.361H6.126V10.21a.681.681,0,0,1-1.361,0V6.126H.681a.681.681,0,0,1,0-1.361H4.764V.681A.681.681,0,0,1,5.445,0Z' transform='translate(7.7) rotate(45)' fill='%23333'/%3E%3C/svg%3E%0A");
            position: relative;
            top: -15px;
            opacity: 1;
        }
    }
    .modal-title.h4 {
        font-size: 22px;
        font-weight: 500;
    }
    .modal-body {
        padding: 0.75rem 1.5rem;
    }
    .modal-footer {
        border-top: none;
        position: relative;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .custom-check-list {
        .form-check {
            position: relative;
            z-index: 0;
            padding: 0;
            margin: 7px 14px 7px 0;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            input {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 15px;
                right: auto;
                margin: auto 0;
                &:focus {
                    box-shadow: none !important;
                }
            }
            label {
                padding: 7px 15px 7px 40px;
            }
            input[type="checkbox"]:checked + label {
                border-color: #1676ee !important;
                background-color: rgba(22, 118, 238, 0.05);
                color: #1676ee;
            }
        }
        .form-select {
            height: 40px;
        }
    }
    .set-time-box {
        .label-txt {
            font-weight: 500;
        }
    }
    .editor-box {
        overflow: hidden;
        padding: 0;
        border: none;
        .editor-strip {
            border-bottom: 1px solid #d8d8d8;
        }
        textarea {
            width: 100%;
            border: none;
            padding: 15px;
            &:focus,
            &:focus-within,
            &:focus-visible {
                border: none;
                outline: none;
            }
        }
    }
    .rounded-10 {
        border-radius: 10px;
    }
    .nav-tabs {
        button:first-child {
            margin-left: 0 !important;
        }
    }
    .nav-link {
        color: #9d9d9d;
        border: none !important;
        margin: 0;
        padding: 10px 0;
        margin: 0 15px;
        position: relative;
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
            border: transparent !important;
            outline: none;
        }
    }
    .nav-item {
        &:first-child {
            .nav-link {
                margin-left: 0;
            }
        }
        &:last-child {
            .nav-link {
                margin-right: 0;
            }
        }
    }
    .nav-link.active {
        color: #333;
        font-weight: 500;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #1676ee;
            left: 0;
            bottom: 0;
        }
    }
    .tab-content {
        padding: 30px 0 0 0;
    }
    .short-code-btns {
        button {
            margin: 0 20px 0 0;
        }
    }
    .btn-upload {
        border: 1px solid #95989a;
        border-radius: 0;
        margin-right: 15px;
        max-width: 100%;
    }
    .upload-box {
        .img-box {
            height: 60px;
            img {
                max-height: 60px;
                width: auto;
            }
        }
        .file-txt {
            white-space: nowrap;
            width: calc(100% - 120px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.custom-noti-box .noti-title, .all-campaign .custom-camp-box .camp-title {
    img {
        align-self: center;
        width: 40px;
        height: 40px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .automated-reminders .reminder-box .custom-noti-box {
        font-size: 13px;
    }
    .automated-reminders .reminder-box .custom-noti-box .noti-time,
    .automated-reminders .reminder-box .custom-noti-box .noti-type,
    .automated-reminders .reminder-box .custom-noti-box .noti-reminder {
        min-width: 1px;
        margin: 0 12px;
    }
}
