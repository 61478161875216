.subscription-sec {
    .max-width-center {
        max-width: 960px;
    }
    .btn-img {
        position: relative;
        top: -2px;
        margin-left: 5px;
    }
    .step-wizard {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 0;
        min-height: 100px;
        ul {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            list-style-type: none;
            padding: 0;
        }
        .step-box {
            position: relative;
        }
        .title {
            // width: 100px;
            margin: 7px auto 0 auto;
            line-height: 20px;
        }
        li {
            display: inline-block;
            text-align: center;
            width: 33%;
            .step {
                text-align: center;
                display: inline-block;
                width: 48px;
                height: 48px;
                text-align: center;
                line-height: 48px;
                border-radius: 50%;
                border: 1px solid #d8d8d8;
                background: #ffffff;
                -webkit-transition: background-color 0.6s ease;
                -o-transition: background-color 0.6s ease;
                transition: background-color 0.6s ease;
                box-shadow: 0px 0px 0px 8px #fff;
                -webkit-box-shadow: 0px 0px 0px 8px #fff;
                -moz-box-shadow: 0px 0px 0px 8px #fff;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: #d8d8d8;
                    top: 24px;
                    z-index: -2;
                    right: 50%;
                }
            }
            &:first-child {
                .step::after {
                    display: none;
                }
            }
            &.active {
                .step {
                    background-color: #0267d4;
                    border: none;
                    color: #fff;
                    img {
                        -webkit-filter: brightness(0) invert(1);
                        filter: brightness(0) invert(1);
                    }
                    &::after {
                        background-color: #0267d4;
                    }
                }
            }
        }
    }

    .price-tag {
        .position-relative {
            top: -16px;
        }
        .fs-1 {
            line-height: 40px;
        }
    }
    .sidebar {
        &.sticky-top {
            top: 30px;
        }
        .plan-btn {
            label.btn {
                color: #333;
                &:hover {
                    background-color: #fff;
                    color: #333;
                }
                &:focus,
                &:active,
                &:hover,
                &:focus-within,
                &:visited {
                    outline: none !important;
                    box-shadow: none !important;
                }
            }
            .btn-check:checked + .btn-outline-primary,
            .btn-check:checked + .btn-outline-primary:hover {
                background-color: #0267d4;
                color: #fff;
            }
        }
        .coupon-input {
            border: 1px solid #95989a;
            border-radius: 4px;
            overflow: hidden;
            input,
            button {
                border-color: transparent !important;
                border-radius: 0;
                &:focus {
                    border-color: transparent !important;
                    box-shadow: none !important;
                }
            }
        }
    }
    .warning-box {
        background-color: #fcf7e4;
        border-color: #faf0ce !important;
        color: #876d40;
        small {
            font-size: 12px;
        }
    }
    .error-box {
        background-color: #efdfdf !important;
        border-color: #e7cecd !important;
        color: #9f4f48;
    }
    .form-card {
        overflow: hidden;
        .card-header {
            background-color: #f3f4f7;
        }
        label {
            font-size: 14px;
        }
        input,
        span.input-group-text {
            &:focus {
                box-shadow: none;
            }
        }
        .StripeElement {
            padding: 10px;
        }
    }
    .saved-card {
        background-color: rgba(40, 87, 245, 0.05);
        border-color: #1676ee !important;
    }
    .save-address {
        background-color: #f3f4f7;
        .btn {
            text-align: center;
            width: 100px;
            background-color: transparent;
        }
    }
    .addon-sidebar {
        height: calc(100% - 1.5rem);
    }
    .upgrade-banner-box {
        border: 1px solid #faf0ce;
        .btn-warning {
            background-color: #f0ad4e !important;
            color: #fff !important;
        }
        .banner-img {
            width: 250px;
            img {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 250px;
            }
        }
        .banner-txt {
            width: 120px;
            line-height: 32px;
            font-size: 28px;
            font-weight: 600;
        }
        .banner-desc {
            p {
                color: #876d40;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .recent-invoice-list {
        li {
            &:nth-of-type(even) {
                background-color: #f3f4f7;
            }
            div:first-child {
                width: calc(60% - 150px);
            }
            div:nth-child(2) {
                width: 25%;
            }
            div:nth-child(3) {
                width: 15%;
            }
            div:nth-child(4) {
                width: 100px;
            }
            div:last-child {
                width: 50px;
            }
        }
    }
    .planTab {
        .nav-link.active,
        .nav-link {
            background-color: #fff !important;
            position: relative;
            font-size: 14px;
            z-index: 0;
        }
        .nav-link.active {
            color: #fff;
            font-weight: 500;
            &::after {
                background-color: #1676ee;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50px;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                z-index: -1;
            }
        }
    }
}
