.main-header {
    border-bottom: 1px solid $color-secondary;
    padding: 13px 30px;
    height: $header-height;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    .noti-btn {
        height: 40px;
        width: 40px;
        span {
            height: 18px;
            line-height: 18px;
            font-size: 12px;
            font-weight: 500;
            min-width: 18px;
            top: -3px;
            right: -3px;
        }
    }
    .img-box-header {
        max-height: 40px;
        max-width: 40px;
        width: 100%;
        height: 100%;
    }
    .txt-header-box {
        line-height: 16px;
    }

    .globe-search {
        position: relative;
        input {
            padding-right: 40px;
            @media only screen and (min-width: 992px) and (max-width: 1199px) {
                width: 280px !important;
            }
        }
        .btn {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .btn.btn-circle {
        span {
            .bi-bell-fill {
                font-size: 18px;
            }
        }
        .badge-floating {
            top: -18px !important;
            left: 25px;
        }
    }
}
.btn {
    &.btn-circle {
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        .badge-floating {
            position: absolute;
            top: -50%;
            transform: translateY(50%);
            border: 3px solid;
        }
    }
}

.pop-notification {
    .dropdown-item {
        white-space: inherit !important;
    }
}
