.login-container {
    background-image: url(../images/loginbg.svg);
    background-size: cover;
    .form-signin {
        width: 100%;
        max-width: 400px;
        padding: 15px;
        margin: auto;
    }
}
