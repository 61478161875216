 
.fab-bottom {
  bottom: 0;
  position: fixed;
  margin: 1em;
  right: 0;
  z-index: 998;

}

.fab-bottom .fab.avatar {
  overflow: visible;
} 
.fabs {
    float: right;
    margin: 0 0 0 10px;
    position: relative;
}
/* .fab-bottom .fabs .MuiAvatar-colorDefault{
  background: #0267d4;
  font-size: 16px;
  text-transform: uppercase;
}
.fab-bottom .fabs .chat_header .MuiAvatar-colorDefault{
  background: #fff;
  font-size: 16px;
  color:#0267d4;
  text-transform: uppercase;
} */
.fab {
  display: block;
  /* width: 60px; */
  /* height: 60px; */
  border-radius: 50%;
  text-align: center;
  color: #f0f0f0;
  margin: 10px auto 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
  cursor: pointer;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  position: relative;
  z-index: 998;
  overflow: hidden;
  background: #0267d4;
  /* line-height: 60px;  */
}

.fab > i {
  font-size: 2em;
  line-height: 55px;
  -webkit-transition: all .2s ease-out;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.fab:not(:last-child) {
  width: 0;
  height: 0;
  margin: 20px auto 0;
  opacity: 0;
  visibility: hidden;
  line-height: 40px;
}

.fab:not(:last-child) > i {
  font-size: 1.4em;
  line-height: 40px;
}

.fab:not(:last-child).is-visible {
  width: 40px;
  height: 40px;
  margin: 15px auto 10;
  opacity: 1;
  visibility: visible;
}

.fab:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 20ms;
  transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 40ms;
  transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 60ms;
  transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 80ms;
  transition-delay: 80ms;
}

/* .fab(:last-child):active,
.fab(:last-child):focus,
.fab(:last-child):hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
} */

/*Chatbox*/

.chat {
  position: absolute;
  right: 0;
  bottom: 60px;
  width: 400px;  
  opacity: 0;
  box-shadow: 1px 1px 100px 2px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  -webkit-transition: all .2s ease-out;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: none;
}
.chat:after {
  clear: both;
  content: "";
  display: block;
  width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  bottom: -9px;
  right: 15px;
}
.chat_fullscreen {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
  }
.chat_header {
    background: #0267d4;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding:8px 20px;
}
 
 
.chat.is-visible {
  opacity: 1;
  -webkit-animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
  display: block;
}
.is-hide{
  opacity: 0
}

  

.chat_option {
  float: left;
  font-size: 15px;
  list-style: none;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: relative;
  margin-right: 10px;
      letter-spacing: 0.5px;
      font-weight: 400
}

.chat_option img {
    border-radius: 50%;
    width: 40px;
    float: left;
    margin: 0 20px;
    border: 4px solid rgba(0, 0, 0, 0.21);
}

.change_img img{
    width: 35px;
    margin: 0px 20px 0px 20px;
}
.chat_option .agent {
  font-size: 12px;
    font-weight: 300;
}
.chat_option .online{
      opacity: 0.4;
    font-size: 11px;
    font-weight: 300;
}
.chat_color {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px;
  float: left;
}


.chat_body {
  background: #fff;
  width: 100%;

  display: inline-block;
  text-align: center;
    overflow-y: auto;

}
#chat_body{
    height: 450px;
}
.chat_body li, .chat_conversion .alert  {
 /* -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1); */
}
.chat_body p {
  padding: 20px;
  color: #888
}
.chat_body a {
  width: 10%;
  text-align: center;
  border: none;
  box-shadow: none;
  line-height: 40px;
  font-size: 15px;
}



.chat_field {
  position: relative;
  margin: 5px 0 5px 0;
  width: 50%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
  outline: none;
  display: inline-block;
}

.chat_field.chat_message {
  height: 60px;
  resize: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  flex-grow: 1;
}
 
.tags{ 
    bottom: 0px;
    display: block; 
}
.tags li{
    padding: 5px 10px;
    border-radius: 40px;
    border: 1px solid rgb(3, 117, 208);
    margin: 5px;
    display: inline-block;
    color: rgb(3, 117, 208);
    cursor: pointer;

}
.fab_field {
  width: 100%;
  display: flex;
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 0 0 10px;
  position: relative;
}
 

.fab_field a {
  display: inline-block;
  text-align: center;
}

 

#fab_send {
  /* float: right; */
  background: rgba(0, 0, 0, 0);
  color: #0267d4;
  margin: 5px 0;
  font-size: 12px;
}

.fab_field .fab i {  
    color: #0267d4 !important; 
}

.fab_field .fab {
  width: 35px;
  height: 35px;
  box-shadow: none;
  margin: 5px;
}

.fab_field .fab>i {
  font-size: 1.6em;
  line-height: 35px;
  color: #bbb;
}
.fab_field .fab>i:hover {
  color: #0267d4;
}

.fab_field textarea:focus + .fab span.material-icons{
  color: #0267d4; 
}

.chat_conversion {

}

.chat_converse {
  position: relative;
  background: #fff;
  margin: 0;
  height: 300px;
  min-height: 0;
  font-size: 12px;
  line-height: 18px;
  overflow-y: auto;
  width: 100%;
  float: right;
  padding:20px;
}
 
.chat_list {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.chat_list .chat_list_item {
  opacity: 0;
  visibility: hidden;
}

.chat .chat_converse .chat_msg_item {
  position: relative;
  margin: 8px 0 15px 0;
  padding: 8px 10px;
  max-width: 84%;
  display: block;
  word-wrap: break-word;
  border-radius: 3px;
  /* -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1); */
  clear: both;
  z-index: 999;
}
.chat .chat_converse .alert { 
    clear: both;
    display: block;
    width: 100%;
}
.msg_date {
    font-size: 11px;
    opacity: 0.5;
}
 
 

.chat .chat_converse .chat_msg_item.chat_msg_item_admin {
  /* margin-left: 20px; */
  float: left;
  background: rgba(0, 0, 0, 0.03);
  color: #666;
  left: 30px;
  max-width: 75%;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user {
  /* margin-right: 20px; */
  float: right;
  background: #E7F1FE;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin:before {
  content: '';
  position: absolute;
  top: 15px;
  left: -12px;
  z-index: 998;
  border: 6px solid transparent;
  border-right-color: rgba(255, 255, 255, .4);
}
 
strong.chat_time {
  padding: 0 1px 1px 0;
  font-weight: 500;
  font-size: 8px;
  display: block;
}

/*Chatbox scrollbar*/

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
/*Element state*/

.is-active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.is-float {
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}

.is-loading {
  display: block;
  -webkit-animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
  animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
}

.pintype {
bottom: 0;
right: -7px;
height: 20px;
width: 20px; 
border-radius: 20px;
border: 1px solid #fff;
line-height: 19px;
font-size: 9px;
}

.pin-checkin .fab {
background: #774087;
}
.pin-checkin .chat_header {
background: #774087;
}

.pin-checkin .pintype {
background: #774087;
}
.pin-chat .pintype {
background: #0267d4;
}

.fab .fab-bcn, 
.fab .fab-bic {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
}

.fab.is-visible .fab-bcn {opacity: 0; visibility: hidden; transform:translateY(-48px) ; -webkit-transform:translateY(-48px) ; -moz-transform:translateY(-48px) ; -ms-transform:translateY(-48px) ; -o-transform:translateY(-48px) ;
}
.fab .fab-bcn { opacity: 1; visibility: visible; transform:translateY(0) ; -webkit-transform:translateY(0) ; -moz-transform:translateY(0) ; -ms-transform:translateY(0) ; -o-transform:translateY(0) ;
}

.fab.is-visible .fab-bic  { opacity: 1; visibility: visible;   transform:translateY(-48px) ; -webkit-transform:translateY(-48px) ; -moz-transform:translateY(-48px) ; -ms-transform:translateY(-48px) ; -o-transform:translateY(-48px) ;
}
.fab .fab-bic { opacity: 0; visibility: hidden; transform:translateY(10px) ; -webkit-transform:translateY(10px) ; -moz-transform:translateY(10px) ; -ms-transform:translateY(10px) ; -o-transform:translateY(10px) ;
}

/*Animation*/

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
/* SMARTPHONES PORTRAIT */

@media only screen and (min-width: 300px) {
  .chat {
    width: 250px;
  }
}
/* SMARTPHONES LANDSCAPE */

@media only screen and (min-width: 480px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/* TABLETS PORTRAIT */

@media only screen and (min-width: 768px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/* TABLET LANDSCAPE / DESKTOP */

@media only screen and (min-width: 1024px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/*Color Options*/



.blue .fab {
  background: #0267d4;
  color: #fff;
}



.blue .chat {
  background: #0267d4;
  color: #999;
}


/* Ripple */

.ink {
  display: block;
  position: absolute;
  background: rgba(38, 50, 56, 0.4);
  border-radius: 100%;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  webkit-transform: scale(0);
  -webkit-transform: scale(0);
          transform: scale(0);
}
/*animation effect*/

.ink.animate {
  -webkit-animation: ripple 0.5s ease-in-out;
          animation: ripple 0.5s ease-in-out;
}

@-webkit-keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/
  
  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    -webkit-transform: scale(5);
            transform: scale(5);
  }
}

@keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/
  
  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    -webkit-transform: scale(5);
            transform: scale(5);
  }
}
::-webkit-input-placeholder { /* Chrome */
  color: #bbb;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #bbb;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #bbb;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: #bbb;
}


.actionBtn {
	background: rgba(0,0,0,0.2);
	margin: -8px -20px 10px -20px;
	border-radius: 10px 10px 0 0;
  padding: 0 10px;
}