.contactless-sec {
    // .custom-rounded-box {
    //     background-color: #f3f4f7;
    //     border: 1px solid #d8d8d8;
    //     border-radius: 10px !important;
    // }
    .feesTxt {
        margin-top: -7px;
        small {
            line-height: 30px;
            padding-right: 5px;
            position: relative;
            top: -4px;
        }
        .feesVal {
            font-size: 22px !important;
            font-weight: 700 !important;
            color: #333 !important;
        }
    }
    .btn-sec {
        input {
            width: 100px;
        }
    }
    .cashless-features {
        padding: 0;
        li {
            margin: 5px 0;
        }
    }
}
